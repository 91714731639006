const config : { 
    Lottery: string,
    CustomerService: string,
    Refund: string,
    ShortPlay: string,
    Receive: string 
} = {
    // 抽奖
    Lottery: 'https://oss-toufang.dcydian.com/index.html#/pages/tuia/ad799_1',
    
    // 在线客服
    // CustomerService: 'https://work.weixin.qq.com/kfid/kfcfe9cd83417ebfe07',
    CustomerService: 'https://work.weixin.qq.com/kfid/kfc276f059dbfd80b07',
    
    // 退款
    // Refund: 'https://work.weixin.qq.com/kfid/kfcfe9cd83417ebfe07',
    Refund: 'https://work.weixin.qq.com/kfid/kfc276f059dbfd80b07',

    // 观看短剧
    // ShortPlay: 'https://jd.aiwanlian.cn/h5/#/',
    ShortPlay: 'https://dj.gxzhimou.cn/h5/',

    // 点击领取
    // Receive: '/api/anon/biz/pay/unifiedOrder?a_oId=123'
    Receive: '/api/anon/biz/pay/unifiedOrder'
    // Receive: 'https://ac8h5.gxzhimou.cn/api/anon/biz/pay/unifiedOrder?a_oId=123'
}
export default config